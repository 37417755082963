import axios from 'axios'
import axiosConfig from './modules/axiosConfig'
import PageHome from './pages/Home.js'
import PageLogin from "./pages/Login";
import UseMouseOrKeyboard from "./modules/UseMouseOrKeyboard";
import DesignSystem from "./DesignSystem/DesignSystem";
import { register } from 'swiper/element/bundle';
import logs from "./utils/logs";
import ripple from "./modules/ripple";
import formErros from "./modules/formErros";
import animaAoScroll from "./animation/animaAoScroll";
import animateSetup from "./animation/animateSetup";
import translate from './pages/translate';
import animationNumbers from './pages/counter'
import contato from './pages/Contato'
import denuncia from './pages/denuncia'
import saveCookies from './modules/saveCookies';
// Init
GLOBAL.DesignSystem = DesignSystem({})
register() // Swiper Web Component

saveCookies()
// Config
axiosConfig({ csrf: GLOBAL.context?.csrf }, axios)
UseMouseOrKeyboard()
ripple()
formErros()
animateSetup()
animaAoScroll()
translate()
animationNumbers()
// Pages

const pageContato = document.querySelector('#contato')
const pageDenuncia = document.querySelector('#denuncia')

// if(pageContato){
// 	contato()
// } else if (pageDenuncia){
// 	denuncia()
// }

if (pageDenuncia) {
	denuncia()
}

const menuItems = document.querySelectorAll('div[js-menu-item]')

if (window.matchMedia('(max-width:1024px)').matches && menuItems) {
	menuItems.forEach((menuLink) => {
		menuLink.addEventListener('click', (e) => {
			e.preventDefault()
			const isAccordion = e.target.closest('div[js-accordion-item]')
			if (!isAccordion) {
				// If it's not an accordion, navigate to the link URL
				const linkElement = e.currentTarget.querySelector('a')
				if (linkElement) {
					window.location.href = linkElement.href
				}
			} else {

			}
		})
	})
}

// Find all accordion items
const accordionItems = document.querySelectorAll('dialog[js-modal="modalMenu"] [js-accordion-item]')

// Iterate through each accordion item
if (accordionItems) {
	accordionItems.forEach((accordionItem, index) => {
		const firstLink = accordionItem.querySelector('div a')

		if (firstLink) {
			// Create a new list item
			const newItem = document.createElement('li')
			newItem.className = 'text-paragraph-1 text-white first:mt-32'
			newItem.innerHTML = `
                <a href="${firstLink.getAttribute('href')}">
                    ${firstLink.textContent}
                </a>
            `

			// Append the new item to the accordion content
			accordionItem.querySelector('ul').appendChild(newItem)
		} else {
			console.error(`No first link found for accordion item ${index + 1}`)
		}
	})
}

window.addEventListener('DOMContentLoaded', () => {
	console.log('dataLang ao reload', localStorage.getItem('dataLang'));
	//
	if (localStorage.getItem('dataLang') === 'null') {
		document.documentElement.setAttribute('lang', 'pt-br');
		console.log('NULL - data-lang', localStorage.getItem('dataLang'), 'lang page', document.documentElement.lang);
	}
	if (localStorage.getItem('dataLang') === 'pt-br') {
		document.documentElement.setAttribute('lang', 'pt-br');
		console.log('PT - data-lang', localStorage.getItem('dataLang'), 'lang page', document.documentElement.lang);
	}
	if (localStorage.getItem('dataLang') === 'en') {
		document.documentElement.setAttribute('lang', 'en');
		console.log('PT - data-lang', localStorage.getItem('dataLang'), 'lang page', document.documentElement.lang);
	}
});


PageHome()
PageLogin()?.init()

// utils logs
logs()

